.inputTabla{
  border: none;
  background-color: transparent;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  justify-content: center;
    margin: auto;
}
.modal-body {
  text-align: center;
  margin: auto;
}
.table{
  border: 2px solid black;
}
.Encabezado{
  background: rgb(255,217,102);
}
.texto{
  text-align: justify;
  margin: auto;
  justify-content: center;
}
.imagenFirma{
  width: 50vh;
  height: 40vh;
}

.sinBorde{
  text-align: center;
  font-size: 20px;
  padding: 20px;
  
}

#navColor{
  color: black;
}

#tablaCalendario td:hover { 
  background-color: #ccc;
}
.hipervinculo{
  text-decoration: none;
  color: inherit;
}
.hipervinculo:hover{
  color:inherit
}
.inspeccion{
  max-width: 1300px !important; 
}
.textoNav{
  height: auto;
  min-width: 400px;
  white-space: inherit !important;
  text-align: justify;
}
.caracterizacion{
  max-width: 100% !important;
}

/* /////////////////////////////////////// */
.tableCaracterizacion{
  padding: 0px !important;
}
.css-1ex1afd-MuiTableCell-root {
  padding: 2px !important;
  text-align: center !important;
  border: 1px solid rgba(224, 224, 224, 1) !important;
}
.inspeccion{
  max-width: 100% !important;
}
.formVehiculo{
  margin: 0px!important;
  padding: 0px!important;
  margin-left: 5px!important;
}
.encuentaFiltro{
  --bs-gutter-x: 0.5rem !important;
}
.nombreVehiculo{
  margin: 0px!important;
  padding: 0px!important;
  width: 46%!important;;
}

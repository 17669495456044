html, body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;

}

.container-fluid {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 800px) {
  .containe-dashboard{
    position: relative !important;
  }
}

#tablaTareas{
  table-layout:fixed;
}

#tablaTareas > thead, tfoot {
  display:table;
  width:calc(100% - 18px);
}
#tablaTareas > tbody {
  overflow:auto;
  overflow-x:hidden;
  display:block;
  width:100%;
  height: 300px;
}
#tablaTareas tr {
  display:table;
  width:100%;
  table-layout:fixed;
}

.modal-80w {
  min-width:80%;
}
